import request from '@/utils/request'


// 查询动态列表
export function listDynamic(query) {
  return request({
    url: '/biz/dynamic/list',
    method: 'get',
    params: query
  })
}

// 查询动态分页
export function pageDynamic(query) {
  return request({
    url: '/biz/dynamic/page',
    method: 'get',
    params: query
  })
}

// 查询动态详细
export function getDynamic(data) {
  return request({
    url: '/biz/dynamic/detail',
    method: 'get',
    params: data
  })
}

// 新增动态
export function addDynamic(data) {
  return request({
    url: '/biz/dynamic/add',
    method: 'post',
    data: data
  })
}

// 修改动态
export function updateDynamic(data) {
  return request({
    url: '/biz/dynamic/edit',
    method: 'post',
    data: data
  })
}

// 删除动态
export function delDynamic(data) {
  return request({
    url: '/biz/dynamic/delete',
    method: 'post',
    data: data
  })
}
