<template>
    <div v-if="videoPic" :style="containerStyle+'display: flex;align-items: center;justify-content: center;background-image: url('+videoPic+');background-size: 100% 100%;'">
      <a-icon  @click="videoPlay()" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave" type="play-circle"/>
      <VideoPlay ref="videoPlay"/>
    </div>
</template>

<script>
    import VideoPlay from './VideoPlay'

    export default {
        name: "VideoModal",
        props: {
            containerStyle: {
                type: String,
                default: "margin: 0 auto;width: 50px;height: 50px;"
            },
            videoUrl: {
                type: String
            },
            videoPic: {
                type: String
            }
        },
        components: {
            VideoPlay
        },
        data() {
            return {
                title: "视频",
                visible: false,
                url: {
                    imgerver: window._CONFIG['fileURL'],
                },
            }
        },
        created() {
        },
        methods: {
            // 视频鼠标悬浮时的样式
            enter: function ($event) {
                $event.currentTarget.className = "enter";
            },
            // 视频鼠标离开时的样式
            leave: function ($event) {
                $event.currentTarget.className = "leave";
            },
            //播放视频
            videoPlay: function () {
                let data = {
                    coverImage: this.videoPic,
                    videoUrl: this.videoUrl
                };
                this.$refs.videoPlay.show(data);
            },
        }
    }
</script>

<style lang="less" scoped>
  .enter {
    cursor: pointer;
    font-size: 25px;
    color: #00A0E9;
  }

  .leave {
    cursor: pointer;
    font-size: 22px;
    color: #00A0E9;
  }
</style>