<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户ID" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户ID" />
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-model-item>
      <a-form-model-item label="地点" prop="location" >
        <a-input v-model="form.location" placeholder="请输入地点" />
      </a-form-model-item>
      <a-form-model-item label="视频宽度" prop="width" >
        <a-input v-model="form.width" placeholder="请输入视频宽度" />
      </a-form-model-item>
      <a-form-model-item label="视频高度" prop="height" >
        <a-input v-model="form.height" placeholder="请输入视频高度" />
      </a-form-model-item>
      <a-form-model-item label="发布类型(dict：travel_type)" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="内容" prop="content" >
        <editor v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item label="内容图片" prop="contentImage" >
        <file-upload v-model="form.contentImage" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="文件地址" prop="fileUrl" >
        <file-upload v-model="form.fileUrl" type="file"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="文件类型" prop="fileType" >
      </a-form-model-item>
      <a-form-model-item label="视频封面" prop="fileCover" >
        <a-input v-model="form.fileCover" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="时长(秒)" prop="timeLength" >
        <a-input v-model="form.timeLength" placeholder="请输入时长(秒)" />
      </a-form-model-item>
      <a-form-model-item label="点赞数" prop="likeNumber" >
        <a-input v-model="form.likeNumber" placeholder="请输入点赞数" />
      </a-form-model-item>
      <a-form-model-item label="评论数" prop="commentNumber" >
        <a-input v-model="form.commentNumber" placeholder="请输入评论数" />
      </a-form-model-item>
      <a-form-model-item label="是否平台发布0否，1是" prop="isSystem" >
        <a-radio-group v-model="form.isSystem" button-style="solid">
          <a-radio-button v-for="(d, index) in isSystemOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否置顶(0：否，其他数字是置顶)" prop="isTop" >
        <a-radio-group v-model="form.isTop" button-style="solid">
          <a-radio-button v-for="(d, index) in isTopOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDynamic, addDynamic, updateDynamic } from '@/api/biz/dynamic'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
    isSystemOptions: {
      type: Array,
      required: true
    },
    isTopOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        title: null,

        location: null,

        width: null,

        height: null,

        type: null,

        content: null,

        contentImage: null,

        fileUrl: null,

        fileType: null,

        fileCover: null,

        timeLength: null,

        likeNumber: null,

        commentNumber: null,

        isSystem: 0,

        isTop: 0,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户ID不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        title: null,
        location: null,
        width: null,
        height: null,
        type: null,
        content: null,
        contentImage: null,
        fileUrl: null,
        fileType: null,
        fileCover: null,
        timeLength: null,
        likeNumber: null,
        commentNumber: null,
        isSystem: 0,
        isTop: 0,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDynamic({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateDynamic(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDynamic(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
